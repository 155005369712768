import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import SubPageMenu from '../../components/Utilities/SubPageMenu'
// import CopyBlock from "../../components/Copy/CopyBlock"
// import InfoBar from '../../components/Utilities/InfoBar'
// import { array } from "prop-types"

class VsSpanishTeams extends React.Component {

  render() {

    const mdata = this.props.data.allSheetMessiTeamsFromX.edges
    const rdata = this.props.data.allSheetRonaldoTeamsFromX.edges

    const teams = rdata.map(d => d.node.competition).reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], [])

    return (

      <Layout>
        <SEO
          title={"vs Spanish Teams - Messi and Ronaldo Goals vs Spanish Teams in Champions League"}
          description={`Who has the most goals vs Spanish teams in the Champions League? Messi or Ronaldo?`}
          canonicalPath={`/all-time-stats/vs-spanish-teams/`}
        />

        <h1>
          vs Spanish Teams <span className="sr-only">- Messi and Ronaldo Goals vs Spanish Teams in Champions League</span>
        </h1>

        

        <SubPageMenu
          pages={[
            { 'name': 'vs English Teams', 'url': '/all-time-stats/vs-english-teams/' },
            { 'name': 'vs German Teams', 'url': '/all-time-stats/vs-german-teams/' },
            { 'name': 'vs Italian Teams', 'url': '/all-time-stats/vs-italian-teams/' },
            { 'name': 'vs French Teams', 'url': '/all-time-stats/vs-french-teams/' },
            { 'name': 'vs Spanish Teams', 'url': 'none', 'active': true },
          ]}
        />

        { teams.map(team => (
            <CompetitionBlock key={team} competition={team} compTitle={team} type="all" subtitle="Champions League Only" modalSubTitle="Champions League Only" mdata={mdata.filter(d => d.node.competition === team )} rdata={rdata.filter(d => d.node.competition === team )} />
          ))
        }

        <LastUpdated type="Stats" />
        
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetMessiTeamsFromX(filter: {pgKey: {regex: "/spanish/"}}) {
      edges {
        node {
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
        }
      }
    }
    allSheetRonaldoTeamsFromX(filter: {pgKey: {regex: "/spanish/"}}) {
      edges {
        node {
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
        }
      }
    }
  }
`

export default VsSpanishTeams
